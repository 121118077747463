
import {MapTypes} from "./MapTypes";
import WowMap from "./WowMap";


export default class Project {

    id:number;
    name: string;
    map_type: MapTypes;
    map:WowMap;
    prepare_dem_url: string;
    prepare_contours_urls: string;


    constructor(id:number,name:string,map_type:MapTypes,map:WowMap,prepare_dem:string,prepare_contours:string) {
        this.id = id;
        this.name = name
        this.map_type=map_type
        this.map=map;
        this.prepare_dem_url=prepare_dem;
        this.prepare_contours_urls=prepare_contours;
    }

    static parse(data: any):Project {
        console.log("parsing Project",data)
        return new Project(
            data.id,
            data.name,
            data.map_type,
            WowMap.parse(data.map),
            data.prepare_dem,
            data.prepare_contours,
        );
    }




}
