import useMediaQuery from "../../custom-hooks/useMediaQuery";
import {ConfigEventType, SCREEN_SIZE_QUERY} from "../../App";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faVectorSquare, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {StepConfigProps} from "./ConfigPanel";
import {AdminPane} from "./AdminPane";
import {ChooseMapType} from "./ChooseMapType";


export function PlacementStepConfig(props: StepConfigProps) {
    const project = props.project;
    const isDesktop = useMediaQuery(SCREEN_SIZE_QUERY);


    let button = <button className={"main-action-btn"}
                         onClick={() => props.on_config_event({eventType: ConfigEventType.PLACE_MAP})}>
        Place here <FontAwesomeIcon icon={faVectorSquare}/>
    </button>;

    let placed =project.map.limits.length!==0;

    let title_text = "Place your map limits";
    if (placed) {
        button = <button onClick={() => props.on_config_event({eventType: ConfigEventType.CLEAR_MAP})}
                         className={"main-action-btn"}>
            Remove <FontAwesomeIcon icon={faWindowClose}/>
        </button>
        title_text = "Are the placed limits good ? "
    }

    const button_orientation = isDesktop ? "column" : "row"

    return <div className={"placement-step-config "}>
        {project.map_type===undefined && <AdminPane>
            <ChooseMapType on_config_event={props.on_config_event}/>
        </AdminPane>}
        <h2 className={"text-center"}>{title_text}</h2>
        {placed && <small className={"text-center"}>You can move them around</small>}
        <div className={"flex-" + button_orientation}>
            {button}
        </div>
        <div className={"flex-column center"}>
            <MapSearchBox
                on_select={(p) => props.on_config_event({eventType: ConfigEventType.CENTER_MAP, payload: p})}/>
        </div>
    </div>

}

function MapSearchBox(props: { on_select: (payload: any) => void }) {
    const ref = useRef<HTMLInputElement>(null);
    let [value, setValue] = useState("")
    let newVar = typeof google;
    useEffect(() => {
        const input = ref.current
        console.log(`mapsearch - input : `, input)
        if (!input || typeof google === "undefined") return;
        const autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }
            props.on_select(place)

            // // If the place has a geometry, then present it on a map.
            // if (place.geometry.viewport) {
            //     props.map.fitBounds(place.geometry.viewport);
            // } else {
            //     props.map.setCenter(place.geometry.location);
            //     props.map.setZoom(17);
            // }
            // marker.setPosition(place.geometry.location);
            // marker.setVisible(true);
        });
    }, [props.on_select, newVar])


    return <div className={"place-search"}>
        <FontAwesomeIcon icon={faSearch}/>
        <input className="map-search-box"
               type="text"
               placeholder="Search your Home"
               ref={ref}
               onInput={event => setValue(event.currentTarget.value)}
               onSelect={event => setValue(event.currentTarget.value)}
        /></div>;
}
