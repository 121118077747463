import React, {Fragment, useContext, useEffect, useState} from 'react';
import {ProjectContext} from "../App";

function AdminLoginWall(props :{ children?: React.ReactNode }) {
    let [logged,setLoggedIn] = useState(false);
    const projectService = useContext(ProjectContext)
    let [email, setEmail] = useState("")
    let [password, setPassword] = useState("")
    useEffect(()=>{
        projectService.addAuthStateListener(a => {
            setLoggedIn(a!==undefined&&!a?.isAnonymous);
        });
    })
    const loggin = async (e: any) => {
        e.preventDefault();
        await projectService.loggin_as_admin(email, password)
        setLoggedIn(true);
        return false
    }

    if (!projectService.is_admin || projectService.is_admin_logged_in()||logged){
        console.log("is_admin",projectService.is_admin)
        console.log("logged",projectService.is_admin_logged_in())
        return <Fragment>{props.children}</Fragment>;
    }

    return <div>
        <form onSubmit={loggin}>
            <label htmlFor="email">Email</label>
            <input type="text" name={"email"} value={email} onChange={(event) => setEmail(event.target.value)}/>
            <label htmlFor="password">Password</label>
            <input type="password" name={"password"} value={password}
                   onChange={(event) => setPassword(event.target.value)}/>
            <button type={"submit"}>Loggin</button>
        </form>
    </div>

}

export default AdminLoginWall;
