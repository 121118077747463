import {useContext, useEffect, useState} from "react";
import Project from "./model/Project";
import {ProjectContext} from "./App";
import {LeafletMapProvider} from "./components/leaflet-components/LeafletMapProvider";
import {OsmLayer} from "./components/leaflet-components/OsmLayer";
import {ClipRectangle} from "./components/leaflet-components/ClipRectangle";
import {FitToBoundsLeaflet} from "./components/leaflet-components/FitToBounds";
import {FreezeLeaflet} from "./components/leaflet-components/FreezeLeaflet";
import {Jobs} from "./components/config/admin/Jobs";

function MapGalleryPreview(props: { project: Project }) {
    const bounds = props.project.map.limits_as_bounds();
    return <LeafletMapProvider map_id={`preview-${props.project.id}`} >
        <OsmLayer/>
        <FitToBoundsLeaflet bounds={bounds}/>
        <FreezeLeaflet/>
        <ClipRectangle bounds={bounds} onBoundsChange={()=>{}} editable={false}/>
    </LeafletMapProvider>

}

function ProjectGalleryElement(props:{project:Project}){
    const project = props.project
    return <a href={`/${props.project.id}`} className={"btn"}>
        {project.map.has_limits() && <MapGalleryPreview project={project}/>}
        <div className={"flex-row center"}><h4>{project.name} </h4> - <small> {project.id}</small></div>
        <div className={"overlay-list"}>{project.map.overlays.map(ov=> <small>{ov.name}</small>)}</div>
        <Jobs project={project}/>
    </a>
}

export function ProjectGallery() {
    let [projects,setProjects] = useState<Array<Project>>([])
    const projectService = useContext(ProjectContext);
    const update_projects = ()=>{
        projectService.getAllProjects().then((projects)=>{
            projects.sort((a:Project,b:Project)=>(b.id-a.id))
            setProjects(projects)
        })
    }
    useEffect(update_projects,[])
    return <div className={"gallery"}>
        <button onClick={()=>projectService.resetProject().then(projectService.updateProject)}>Create New</button>
        {projects.map(p=><ProjectGalleryElement project={p}/>)}
    </div>;
}
