import {LeafletSubComponent} from "./LeafletSubComponent";
import L from "leaflet";
import React from "react";
const add = (map:any) => {
    console.log("osm add")
    return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        className: "notCliped",
        // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
};
const remove = (map:any, context:any) => {
    context?.remove()
};
export function OsmLayer() {

    return <LeafletSubComponent add={add} remove={remove}
    />
}
