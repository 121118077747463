import {useContext} from "react";
import {LeafletMapContext} from "./LeafletMapProvider";

export function FreezeLeaflet() {
    let map = useContext(LeafletMapContext);
    map?.dragging.disable();
    map?.touchZoom.disable();
    map?.doubleClickZoom.disable();
    map?.scrollWheelZoom.disable();
    map?.boxZoom.disable();
    map?.keyboard.disable();
    if (map?.tap) map.tap.disable();
    map?.zoomControl.remove()
    return null;
}
