import L, {LatLngBounds, LatLngBoundsExpression, LeafletEvent, Point, PolylineOptions} from "leaflet";
import {debounce} from "lodash";
import {LeafletSubComponent} from "./LeafletSubComponent";
import React from "react";

interface ClipRectangleProps {
    bounds: LatLngBoundsExpression;
    onBoundsChange: (bounds: LatLngBounds) => void;
    editable?:boolean,
    forced_ratio?:null|{height:number,width:number}
}

function getOrCreateChild(svg: Element, tag: string) {
    const tags = svg.getElementsByTagName(tag)
    let defs;
    if (tags.length === 0) {
        defs = document.createElementNS("http://www.w3.org/2000/svg", tag)
        svg.appendChild(defs)
    } else {
        defs = tags[0]
    }
    return defs
}

function createClipPath(e: LeafletEvent) {
    updateClipPath(e.target._path)
    const svg = e.target._path.closest("svg")!
    const defs = svg.getElementsByTagName("defs")[0]
    const filterBlur = document.createElement("filter")
    defs.appendChild(filterBlur)
    filterBlur.setAttribute("id", "blur")
    let stdDeviation = getOrCreateChild(filterBlur, "feGaussianBlur")
    stdDeviation.setAttribute("stdDeviation", "2")

    const filterGrayscale = document.createElement("filter")
    defs.appendChild(filterGrayscale)
    filterGrayscale.setAttribute("id", "grayscale")
    let colorMatrix = getOrCreateChild(filterGrayscale, "feColorMatrix")
    colorMatrix.setAttribute("type", "saturate")
    colorMatrix.setAttribute("values", "0")

}

function updateClipPath(e: SVGPathElement) {
    const svg = e.closest("svg")!
    let defs = getOrCreateChild(svg, "defs");
    let clipPath = getOrCreateChild(defs, "clipPath")
    clipPath.id = "clipPath"
    let path = getOrCreateChild(clipPath, "path")
    // @ts-ignore
    path.attributes.setNamedItem(e.attributes.getNamedItem("d").cloneNode())
    path.setAttribute("fill", "white")
    path.setAttribute("opacity", "0.9")

}

export function ClipRectangle(props: ClipRectangleProps) {
    let editable = props.editable===undefined||props.editable;
    const add = (map: L.Map) => {
        let rec = L.rectangle(props.bounds, {draggable: editable} as PolylineOptions)
        rec.setStyle({"className": "clip", fillOpacity: 0})
        rec.on({"add": createClipPath})
        let ratio_marker = L.marker(rec.getBounds().getCenter(),{opacity:0.01});
        ratio_marker.bindTooltip("",{permanent: true, className: "my-label", offset: [0, 0] });
        let target_vertex;
        let rectify_ratio = ()=>{
            const bounds = rec.getBounds();

            let {height,width} = props.forced_ratio!
            const ne = map.latLngToLayerPoint(bounds.getNorthEast());
            const sw = map.latLngToLayerPoint(bounds.getSouthWest());
            const north = ne.y;
            const south = sw.y;
            const east = ne.x;
            const west = sw.x;
            let map_height=Math.abs(north-south);
            let map_width= Math.abs(east-west);
            let current_ratio=map_height/map_width;
            let target_ratio=height/width;
            if (target_ratio<current_ratio){
                let target_height=height*map_width/width;
                rec.setBounds(new LatLngBounds({lat:bounds.getSouth(),lng:bounds.getWest()},map.layerPointToLatLng(new Point(east,south-target_height))));
            }
            else{
                let target_height=height*map_width/width;
                rec.setBounds(new LatLngBounds({lat:bounds.getSouth(),lng:bounds.getWest()},map.layerPointToLatLng(new Point(east,south-target_height))))
            }

        }
        // @ts-ignore
        if(editable)
            rec.enableEdit(map);
        rec.on({
            "editable:editing": (e) => {
                let path = e.target._path
                updateClipPath(path)

            },"editable:vertex:dragstart":(e)=>{
                target_vertex=e.vertex;
                ratio_marker.addTo(map);
            },
            "editable:vertex:drag":(e)=>{
                console.log(e);
                const bounds = rec.getBounds();
                ratio_marker.setLatLng(bounds.getCenter());
                const ne = map.latLngToLayerPoint(bounds.getNorthEast());
                const sw = map.latLngToLayerPoint(bounds.getSouthWest());
                const north = ne.y;
                const south = sw.y;
                const east = ne.x;
                const west = sw.x;
                let height=Math.abs(north- south);
                let width= Math.abs(east- west);
                ratio_marker.getTooltip()?.setContent((height/width).toString())

                },
            "editable:vertex:dragend": (e) => {
                ratio_marker.remove()
                if(props.forced_ratio!==undefined && props.forced_ratio!==null){
                    rectify_ratio()
                }
                props.onBoundsChange(rec.getBounds())

            }
        })
        rec.on({
            "drag": (e) => {
                updateClipPath(e.target._path)
            },
            "dragend": (e) => {
                props.onBoundsChange(rec.getBounds())
            }
        })
        rec.addTo(map)
        let on_zoom_change = debounce(() => {
            try{
                // @ts-ignore
                updateClipPath(rec._path)
            }catch (e) {
                on_zoom_change()
            }
        }, 100)
        map.on("zoomend", () => {
            on_zoom_change()
        })
        return [rec,ratio_marker]
    }
    const remove = (map: L.Map, element_list: any) => {
        for (const elementListElement of element_list) {
            elementListElement.remove();
        }
    }
    return <LeafletSubComponent add={add} remove={remove}/>
}
