import L, {LatLngBounds} from "leaflet";
import "leaflet.vectorgrid";
import "leaflet-editable";
import "leaflet.path.drag";
import {LeafletSubComponent} from "./LeafletSubComponent";
import React, {useEffect, useState} from "react";
import _ from "lodash";

export const TILE_SERVER_URLS = {
    VECTOR_PATH: (vector_function: string, options?: string) => {
        return {
            url: `${process.env.REACT_APP_VECTOR_ROOT}${vector_function}/{z}/{x}/{y}.pbf${options !== undefined ? "?" + options : '?'}`,
            id: vector_function,
            args: options
        }
    },
    EXTENT_OPTION: (extent: LatLngBounds) => `south=${extent.getSouth()}&north=${extent.getNorth()}&east=${extent.getEast()}&west=${extent.getWest()}`,
    RASTER_PATH: () => `${process.env.REACT_APP_RASTER_ROOT}/tile/{z}/{x}/{y}.png`,
    VECTOR_MOTORWAYS: (level: any) => TILE_SERVER_URLS.VECTOR_PATH("public.roads", `level=${level}`),
    CONTOURS: (spacing: number) => TILE_SERVER_URLS.VECTOR_PATH("public.contours_by_interval", `spacing=${spacing}`),
    CONTOURS_BY_COUNT: (count: number, extent: LatLngBounds) => TILE_SERVER_URLS.VECTOR_PATH("public.contours_by_count", `count=${count}&${TILE_SERVER_URLS.EXTENT_OPTION(extent)}`),
    BACKGROUND: () => TILE_SERVER_URLS.VECTOR_PATH("public.total_extent"),
    VECTOR_RIVERS: () => TILE_SERVER_URLS.VECTOR_PATH("public.rivers"),
    VECTOR_RAILWAYS: () => TILE_SERVER_URLS.VECTOR_PATH("public.railways")
}
export const VECTOR_LAYER_STYLES:any={
    background:{"fill": true, "fillOpacity": 1, "fillColor": "rgb(215,215,215)",color:"rgba(255,255,255,0)"},
    "contours": {color: "#836235", weight: 1.5},
    "public.railways":{color: "#8D8D8DFF", weight: 1.5,opacity:1},
    "public.rivers":{fill:true,fillColor: "#386c91", weight: 0.5,fillOpacity:1},
    "public.roads":{color: "#836235", weight: 1,opacity:1}
}
export interface VectorLayerStyle {
    [attribute_name: string]: any,
}

export interface VectorLayerStyles {
    [layer_name: string]: VectorLayerStyle
}

export interface VectorLayerPath {
    url: string,
    id: string,
    args: any
}


export interface VectorLayerProps {
    layerStyle: VectorLayerStyles;
    vectorPath: VectorLayerPath;
    minZoom?: number;
    maxZoom?: number;
}

function addVectorLayer(map: any, layer_path: VectorLayerPath, styles: VectorLayerStyles, maxZoom?: number, minZoom?: number) {
    var vectorTileStyling = {};
    for (let stylesKey in styles) {
        // @ts-ignore
        vectorTileStyling[stylesKey] = {
            "fill": false,
            "fillOpacity": 0.1,
            "opacity": 1,
            "weight": 2,
            ...styles[stylesKey]
        }
    }
    console.log("Vector tile styling", vectorTileStyling)
    var vectorTileOptions = {
        "rendererFactory": (L.canvas as any).tile,
        "attribution": "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors, made with Natural Earth",
        "vectorTileLayerStyles": vectorTileStyling,
        "className": "clipped-map-tiles",
        "maxNativeZoom": maxZoom,
        "minZoom": minZoom,
    };

    var vectorLayer = L.vectorGrid.protobuf(layer_path.url, vectorTileOptions).addTo(map);
    vectorLayer.on("remove", (e: any) => {
        // vectorLayer.cancel()
    })
    vectorLayer.on("load", (e: any) => {
        console.log("loaded", e)
    })
    return vectorLayer
}

function remove(map: L.Map, context: any) {
    context?.remove()
}

export function VectorLayer(props: VectorLayerProps) {
    let [add, setAdd] = useState(() => {
        return function (map: L.Map) {
            return addVectorLayer(map, props.vectorPath, props.layerStyle, props.maxZoom, props.minZoom,);
        }
    })
    let [savedProps, setProps] = useState(props)

    useEffect(() => {
        if(!_.isEqual(props,savedProps)){
            setAdd(() => {
                return function (map: L.Map) {
                    return addVectorLayer(map, props.vectorPath, props.layerStyle, props.maxZoom, props.minZoom,);
                }
            })
            setProps(props)
        }

    }, [props.vectorPath])
    // const updateBounds=  (context:any,props:{bounds:any})=>{
    //     console.log(context)
    //     if(context)
    //         context.options.bounds = props.bounds?.pad(0.5)
    //     // context?.setBounds(props.bounds?.pad(0.5))
    // }
    return <LeafletSubComponent add={add} remove={remove}/>
}
