import {ConfigEventCallback, ConfigEventType, ProjectContext} from "../../App";
import {MapTypes} from "../../model/MapTypes";
import {useContext} from "react";

export function ChooseMapType(props: { on_config_event: ConfigEventCallback }) {
    const service = useContext(ProjectContext)
    const updateType=(type:MapTypes)=>{
        props.on_config_event({eventType:ConfigEventType.MAP_TYPE,payload:type})
    }
    return <div>
        <h2>Choose Map Type</h2>
        <small>Current : {service.current_project?.map_type}</small>
        <div>
            <button onClick={()=>updateType(MapTypes.KEY_CHAIN)}>KeyChain</button>
            <button onClick={()=>updateType(MapTypes.CUSTOM_MAP)}>Custom Map</button>
            <button onClick={()=>updateType(MapTypes.BORDERED_MAP)}>Bordered Map</button>
        </div>
    </div>
}
