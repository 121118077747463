import {Fragment, useContext} from "react";
import {ProjectContext} from "../../App";

export function AdminPane(props: { children?: React.ReactNode }) {
    const projectService = useContext(ProjectContext);
    if (projectService.is_admin && projectService.is_admin_logged_in()) {
        return <Fragment>{props.children}</Fragment>
    }
    return <div/>;
}
