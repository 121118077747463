import {Coordinate} from "./Coordinate";
import Overlay from "./Overlay";
import {LatLngBounds} from "leaflet";

export default class WowMap{
    id:number;
    layer_count:number;
    number:number;
    client_id: string;
    limits: Array<Coordinate>
    overlays: Array<Overlay>
    final_files=[];


    constructor(id: number, layer_count: number, number: number, client_id: string, limits: Array<Coordinate>, overlays: []) {
        this.id = id;
        this.layer_count = layer_count;
        this.number = number;
        this.client_id = "admin";
        this.limits = limits;
        this.overlays = overlays;
    }
    static parse(data:any){
        return new WowMap(
            data.id,
            data.layer_count,
            data.number,
            data.client_id,
            data.limits.map((v:any)=>{
                return {latitude:parseFloat(v.latitude),longitude:parseFloat(v.longitude)}
            }),
            data.overlays.map(Overlay.parse),
        )
    }

    limits_as_bounds() {

        return new LatLngBounds( this.limits.map(c=>[c.latitude,c.longitude]));
    }

    has_limits() {
        return this.limits.length===2;
    }
}
