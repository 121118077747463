import {VECTOR_LAYER_STYLES} from "../components/leaflet-components/VectorLayer";

export default class Overlay {
    name: string;
    query: string;//Comma separated arguments
    is_default: boolean;
    database: string;


    constructor(name: string, query: string, is_default: boolean, database: string) {
        this.name = name;
        this.query = query;
        this.is_default = is_default;
        this.database = database;
    }

    static parse(data: any) {
        return new Overlay(
            data.name,
            data.query,
            data.default,
            data.database,
        )
    }

    ;

    get_styles() {
        let styles:any = {};
        styles[this.database] = VECTOR_LAYER_STYLES[this.database]
        console.log(styles)
        return styles;
    }
}
export const DefaultOverlays={
    MOTORWAYS:new Overlay("Motorways","level=motorway,primary,secondary,tertiary,trunk",true,"public.roads"),
    STREETS:new Overlay("Streets","level=unclassified,residential,road,service",true,"public.roads"),
    PATHS:new Overlay("Path","level=path",true,"public.roads"),
    RIVER:new Overlay("Rivers","",true,"public.rivers"),
    RAILWAYS:new Overlay("Rail","",true,"public.railways")
}
