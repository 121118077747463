import {LatLngBounds} from "leaflet";
import React, {Fragment} from "react";
import {TILE_SERVER_URLS, VECTOR_LAYER_STYLES, VectorLayer} from "./VectorLayer";

export function ElevationLayer(props: { bounds: LatLngBounds, count: number }) {
    return (
        <Fragment>
            <VectorLayer layerStyle={{"public.contours_by_count":VECTOR_LAYER_STYLES.contours}}
                         vectorPath={TILE_SERVER_URLS.CONTOURS_BY_COUNT(props.count, props.bounds)}
            />
        </Fragment>
    )
}
