import {LatLngBounds} from "leaflet";
import {TILE_SERVER_URLS, VECTOR_LAYER_STYLES, VectorLayer} from "./VectorLayer";
import React, {useState} from "react";
const vectorPath = TILE_SERVER_URLS.BACKGROUND();
const layerStyle = {"public.total_extent": VECTOR_LAYER_STYLES.background};

export function BackgroundLayer() {

    return <VectorLayer
        layerStyle={layerStyle}
        vectorPath={vectorPath}/>
}
