import Project from "../../../model/Project";
import {FirestoreCollection} from "../../FirestoreProxies";
import React from "react";

function JobsListView(props: { isLoading: boolean, data: any }) {
    console.log("list view", props)
    if (props.isLoading) {
        return <div></div>
    }
    let jobs = props.data
    return <div>
        {
            jobs.map((j: any) => {
                const content = j[j.id];
                return <div>
                    <div className={"job-list"}>
                        <h5>{j.id} - {content.progress}%</h5>
                        {content.file && <a target={"_blank"} href={content.file}>File</a>}
                    </div>
                    <div>
                        <div style={{
                            width: "90%",
                            border: "solid white 1px",
                            borderRadius: "2px",
                            background: "lightblue"
                        }}>
                            <div
                                style={{height: "3px", background: "#2980b9", width: `${content.progress}%`}}></div>
                        </div>
                    </div>
                </div>
            })
        }
    </div>
}

export function Jobs(props: { project: Project }) {
    console.log(props.project.id)
    return <FirestoreCollection path={`/projects/${props.project.id}/jobs`} render={JobsListView}/>
}
