import Project from "../../model/Project";
import React, {useState} from "react";
import {FirestoreCollection} from "../FirestoreProxies";
import {SVGMapPreview} from "../SVGMapPreview";
import {MapLayers} from "../../App";
import {LeafletMapProvider} from "../leaflet-components/LeafletMapProvider";
import {LatLngBounds} from "leaflet";
import {OsmLayer} from "../leaflet-components/OsmLayer";
import {WowLeafletConsumer} from "../leaflet-components/WowLeafletConsumer";
import {FitToBoundsLeaflet} from "../leaflet-components/FitToBounds";

function FinalPreviewImages(props: { img: any, svg: any }) {
    let [showImg, setShowImg] = useState(true)
    let preview;
    if (showImg) {
        preview = <img src={props.img} className={"final_preview"} alt="Final Map Preview"/>
    } else {
        preview = <SVGMapPreview svg={props.svg} png={props.img}/>
    }
    return preview
}

function FinalPreviewView(props: { project: Project }) {
    function FinalPreviewRender(props: { isLoading: boolean, documents: any }) {
        let errorMessage: JSX.Element | undefined = undefined;
        if (props.isLoading || props.documents === undefined) {
            errorMessage = <div>Generating Your Map's Preview</div>;

        }
        const finalPreview = props.documents.find((s: any) => s.id === "FP")
        if (finalPreview === undefined) {
            errorMessage = <div>An Error Occured</div>
        } else if ((!Object.keys(finalPreview).includes("BuildMapPreview"))) {
            errorMessage = <div>Generating Your Map's Preview</div>
        }
        if (errorMessage !== undefined) {
            return errorMessage;
        }

        return <FinalPreviewImages img={finalPreview.BuildMapPreview["file-png"]}
                                   svg={finalPreview.BuildMapPreview["file-svg"]}/>

    }

    const renderWithFirestore = (p: any) => {
        return <FinalPreviewRender isLoading={p.isLoading} documents={p.data}/>
    }
    return <div className={"final_preview"}>
        <FirestoreCollection path={`projects/${props.project.id}/steps`} render={renderWithFirestore}/>
    </div>;
}

export function MapPreview(props: { project: Project, update_project: (update: (project?: Project) => Project) => void, placed_map: (latlng:LatLngBounds) => void, place_here: boolean, cleared_map: () => void, clear_map: boolean, map_center: (null | google.maps.places.PlaceResult) ,hidden_layers:Set<string>,map_ratio:any}) {

    let preview: JSX.Element;
    preview = <LeafletMapProvider map_id={'leaflet-components-map'}>
        <OsmLayer/>
        <WowLeafletConsumer squareCoordinates={[]} project={props.project}
                            update_project={props.update_project} placed_map={props.placed_map}
                            update_coords={props.place_here} cleared_map={props.cleared_map}
                            clear_map={props.clear_map}
                            overlays={props.project.map.overlays}
                            contours_count={props.project.map.layer_count}
                            map_center={props.map_center!}
                            hidden_layers={props.hidden_layers}
                            map_ratio={props.map_ratio}
        />
        {props.project.map.has_limits() && <FitToBoundsLeaflet bounds={props.project.map.limits_as_bounds()}/>}
            </LeafletMapProvider>;


    return <div className={"map-preview"}>
        {preview}
    </div>
}
