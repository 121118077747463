import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {LatLngBounds, LatLngBoundsLiteral} from "leaflet";
import {throttle} from "lodash";
import {BackgroundLayer} from "./BackgroundLayer";
import {ClipRectangle} from "./ClipRectangle";
import {LeafletMapContext} from "./LeafletMapProvider";
import {Coordinate} from "../../model/Coordinate";
import Project from "../../model/Project";
import Overlay from "../../model/Overlay";
import {OverlayLayer} from "./OverlayLayer";
import {ElevationLayer} from "./ElevationLayer";

export interface MapProps {
    squareCoordinates: Array<Coordinate>;
    project: Project;
    update_project: (update: (project?: Project) => Project) => void;
    placed_map: (latLngBounds: LatLngBounds) => void;
    update_coords: boolean;
    cleared_map: () => void;
    clear_map: boolean;
    overlays: Array<Overlay>;
    map_center: google.maps.places.PlaceResult;
    contours_count?: number;
    hidden_layers:Set<string>;
    map_ratio:any;
}


export function WowLeafletConsumer(props: MapProps) {
    let map = useContext(LeafletMapContext);
    let [bounds, setBounds] = useState<LatLngBounds | undefined>(
        () => {
            if (props.project.map.limits.length===2){
                return  props.project.map.limits_as_bounds();

            }
            return undefined
            }
    )
    let onBoundsChange = useMemo(() => throttle((new_bounds: LatLngBounds) => {
        setBounds(new_bounds);
        props.placed_map(new_bounds)
    }, 2000, {leading: false}), [setBounds]);
    useEffect(() => {
        if (map !== undefined && props.update_coords) {
            setBounds(map!.getBounds().pad(-0.10))
            props.placed_map(map!.getBounds().pad(-0.10))
        }
    }, [map, props.update_coords])
    useEffect(()=>{
        if(props.project.map.limits.length!==2){
            setBounds(undefined)
        }
    },[props.project.map.limits])
    if (!map) {
        return <div></div>;
    }
    if (bounds) {
        const overlays = props.overlays;
        // console.log(`WowLeafletConsumer - picked_overlays.has(Overlays.RIVERS) : `,picked_overlays.has(Overlays.RIVERS))
        // let motorways = picked_overlays.has(Overlays.MOTORWAYS);
        // let streets = picked_overlays.has(Overlays.STREETS);
        // let paths = picked_overlays.has(Overlays.PATHS)
        let count = props.project.map.layer_count;
        console.log("wow leaflet",props.hidden_layers)
        return <Fragment>
            <BackgroundLayer/>
            <ClipRectangle bounds={bounds} onBoundsChange={onBoundsChange} forced_ratio={props.map_ratio}/>
            {overlays.filter(ov=>!props.hidden_layers.has(ov.name)).map((ov: Overlay) => <OverlayLayer overlay={ov}/>)}
            {count && !props.hidden_layers.has("Contours")&& <ElevationLayer bounds={bounds} count={count}/>}
            {/*{picked_overlays.has(Overlays.RIVERS) && <RiverLayer />}*/}
            {/*{picked_overlays.has(Overlays.RAILWAYS) && <RailwaysLayer/>}*/}
        </Fragment>
    }
    return null;
}
