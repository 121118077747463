
import React, {Fragment, useState} from "react";
import Project from "../../model/Project";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import {ConfigEvent, ConfigEventType, SCREEN_SIZE_QUERY} from "../../App";
import Overlay, {DefaultOverlays} from "../../model/Overlay";

const OVERLAYS_NAMES = new Map([
        // [Overlays.MOTORWAYS, "Main Roads"],
        // [Overlays.RIVERS, "Rivers"],
        // [Overlays.STREETS, "Streets"],
        // [Overlays.RAILWAYS, "Railways"],
        // [Overlays.PATHS, "Paths"],
        // [Overlays.FORESTS, "Forests"],
        // [Overlays.NAMES, "Names"],

    ]
)

function AddCustomOverlay(props: { on_add: (overlay: Overlay) => void }) {
    let [name,setName] = useState("");
    let [funcName,setFuncName] = useState("")
    let [query,setQuery] = useState("")

    return <div className={"flex-column"}>
        <h4>Add Custom</h4>
        <label htmlFor="name">Name</label>
        <input type="text" name={"name"} value={name} onChange={(event) => setName(event.target.value)}/>
        <label htmlFor="function">Function Name</label>
        <input type="text" name={"function"} value={funcName} onChange={(event) => setFuncName(event.target.value)}/>
        <label htmlFor="query">Query</label>
        <input type="text" name={"query"} value={query} onChange={(event) => setQuery(event.target.value)}/>
        <button onClick={()=>{
        props.on_add(new Overlay(name, query,false, funcName))}
        }>Add</button>
    </div>;
}

export function OverlayStepConfig(props: { project: Project, on_config_event: (event: ConfigEvent) => void }) {

    const isDesktop = useMediaQuery(SCREEN_SIZE_QUERY);
    const galery_horientation = isDesktop ? "vertical-galery" : "horizontal-galery"
    const [shown_overlays, setShown] = useState<Array<Overlay>>(props.project.map.overlays)
    const switchOverlay = (overlay: Overlay) => {
        let new_overlays;
        if (shown_overlays.includes(overlay)) {
            new_overlays = shown_overlays.filter(o => o !== overlay);
        } else {
            new_overlays = [...shown_overlays, overlay];
        }

        setShown(new_overlays)
        props.on_config_event({
            eventType: ConfigEventType.SELECT_OVERLAYS,
            payload: new_overlays
        })
    }
    let overlays = Array.from(props.project.map.overlays);
    Object.values(DefaultOverlays).filter((def)=>overlays.every((ov)=>def.name!==ov.name))
        .forEach(def=>overlays.push(def))
    overlays.sort((a,b)=>a.name.localeCompare(b.name))
    return <div className={"step-view"}>
        <div className={"flex-row " + galery_horientation}>
            {overlays.map((overlay:Overlay)=>{
                let className="";
                if (shown_overlays.includes(overlay)) {
                    className = "btn-highlight"
                }
                  return <div className={"flex-column text-center"} onClick={() => switchOverlay(overlay)}
                              key={overlay.name}>
                      <button disabled={false}
                              className={className + " preview-miniature"}
                      >

                          {overlay.name}

                      </button>
                  </div>
            })
            }
            {/*{overlay_keys.map((overlay_key: Overlays) => {*/}
            {/*    let name = OVERLAYS_NAMES.get(overlay_key)*/}
            {/*    let className = "";*/}
            {/*    if (shown_overlays.includes(overlay_key)) {*/}
            {/*        className = "btn-highlight"*/}
            {/*    }*/}
            {/*    let is_disabled = false*/}

            {/*    return <div className={"flex-column text-center"} onClick={() => switchOverlay(overlay_key)}*/}
            {/*                key={overlay_key}>*/}
            {/*        <button disabled={is_disabled}*/}
            {/*                className={className + " preview-miniature"}*/}
            {/*        >*/}
            {/*            /!*<img src={overlay_data} alt=""/>*!/*/}
            {/*            {name}*/}

            {/*        </button>*/}
            {/*    </div>*/}

            {/*})}*/}
        </div>
        <AddCustomOverlay on_add={switchOverlay}/>
    </div>;
}
