
import React, {useEffect, useRef, useState} from "react";
import L from "leaflet";
import "leaflet-editable"
import "leaflet";
import "leaflet";
import {OsmLayer} from "./OsmLayer";
import {MapProps, WowLeafletConsumer} from "./WowLeafletConsumer";

export const LeafletMapContext = React.createContext<L.Map | undefined | null>(null)

export function LeafletMapProvider(props: { children?: React.ReactNode,map_id:string}) {
    const mapRef = useRef(null)
    let [currentMap, setMap] = useState<L.Map | undefined>(undefined)

    useEffect(() => {
        if (mapRef.current && currentMap === undefined) {
            var map = L.map(props.map_id, {editable: true,    zoomDelta: 0.25,zoomSnap:0.25}).setView([43.70245, 7.28574], 13);
            setMap(map)
        }
    }, [mapRef.current])
    // useEffect(()=>{
    //     if(currentMap!==undefined && props.map_center){
    //         console.log(props.map_center)
    //         //currentMap.flyToBounds(props.map_center.)
    //     }
    // },[props.map_center])

    return <div className={"map-preview"} id={props.map_id} ref={mapRef}>
        <LeafletMapContext.Provider value={currentMap}>
            {props.children}
        </LeafletMapContext.Provider>
    </div>
}
