import Project from "./Project";
import firebase from "firebase";
import {MapTypes} from "./MapTypes";
import Overlay from "./Overlay";
import {Coordinate} from "./Coordinate";

interface ProjectCallback {
    (project: Project): void
}

const MapMakerApi = {
    URLS: {
        reset: (id: string) => `${process.env.REACT_APP_ENGINE_API_URL}/mapprojects/reset/${id}`,
        project: (id: string) => `${process.env.REACT_APP_ENGINE_API_URL}/mapprojects/${id}`,
        projects: () => `${process.env.REACT_APP_ENGINE_API_URL}/mapprojects/?map__client_id=admin`,
        launch_sub_tasks: (subtask:string,project:Project) =>
            `${process.env.REACT_APP_ENGINE_API_URL}/mapprojects/${project.id}/launch_${subtask}/`,
        createProject: (type:MapTypes) => `${process.env.REACT_APP_ENGINE_API_URL}/mapprojects/create/${type}`,
        validateStep: (project_id: number) => `${process.env.REACT_APP_ENGINE_API_URL}/validatestep/${project_id}`,
        reserve_workers(project_id: number) {
            return `${process.env.REACT_APP_ENGINE_API_URL}/reserve_workers/${project_id}`
        },

    }
}


export class ProjectService {

    private _current_project ?: Project;
    private auth;
    private firestore;
    is_admin: boolean;

    constructor(admin:boolean) {
        this.auth = firebase.auth();
        this.firestore = firebase.firestore();
        this.is_admin = admin
    }

    set current_project(value: Project | undefined) {
        this._current_project = value;
    }

    get current_project(): Project | undefined {
        return this._current_project;
    }


    async loadOrCreateProject(type?:MapTypes): Promise<Project> {
        if(type===undefined)type=MapTypes.CUSTOM_MAP
        let id = await this.get_project_id()
        let url = id !== null ? MapMakerApi.URLS.project(id) : MapMakerApi.URLS.createProject(type)
        this._current_project = await Project.parse(await (await fetch(url)).json())
        await this.set_project_id(this._current_project.id.toString())
        return this._current_project
    }




    updateCurrentProject(updated: Project) {
        this._current_project = updated
    }


    private async get_project_id() {
        await this.signInAnonymously();
        let user = this.auth.currentUser;
        let document = await this.firestore.collection("users").doc(user?.uid).get()
        const match = /\/(\d+)/gm.exec(window.location.pathname)
        if (match!==null){
            return parseInt(match[1]);
        }
        return document.data()?.project_id ?? null
    }

    private async set_project_id(id: string) {
        await this.signInAnonymously();
        let user = this.auth.currentUser;
        let doc = this.firestore.collection("users").doc(user?.uid);
        await doc.set({"project_id": id}, {merge: true})
    }

    private async signInAnonymously() {
        if (!this.auth.currentUser)
        await this.auth.signInAnonymously();
    }

    async resetProject(type?:MapTypes): Promise<Project> {
        if (type === undefined)type=MapTypes.CUSTOM_MAP;
        const id = this._current_project?.id;
        // if (id === undefined) {
        //     throw new Error("The current id should not be null")
        // }
        // let url = MapMakerApi.URLS.reset(id.toString())
        // this._current_project = await Project.parse(await (await fetch(url)).json())
        await this.set_project_id(null!);
        await this.loadOrCreateProject(type);
        return this._current_project!
    }

    async reserve_workers() {
        const id = this._current_project?.id
        if (id ===undefined)throw new Error("Project should have been defined by this point")
        return await fetch(MapMakerApi.URLS.reserve_workers(id));
    }

    async retryInError() {
        await this.set_project_id(null!);
        window.location.reload()
    }

    is_admin_logged_in() {
        console.log(this.auth.currentUser);
        return this.auth.currentUser && !this.auth.currentUser?.isAnonymous

    }

    async loggin_as_admin(email: string, password: string) {
        return await this.auth.signInWithEmailAndPassword(email, password)
    }
    addAuthStateListener(listener: firebase.Observer<any, Error> | ((a: firebase.User | null) => any)){
        this.auth.onAuthStateChanged(listener);
    }

    updateOverlays(project: Project, new_overlays: Array<Overlay>) {
        project.map.overlays=new_overlays
       this.updateProject(project);
        return project

    }

    async updateProject(project: Project) {
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(project)
        };
        let id = project.id;
        if (id ===undefined)throw new Error("Project should have been defined by this point")
        return Project.parse(await(await fetch(MapMakerApi.URLS.project(id.toString())+"/", requestOptions)).json());

    }

    async getAllProjects(){
        const data = (await(await(fetch(MapMakerApi.URLS.projects()))).json())
        return data.map(Project.parse);
    }

    updateLimits(project:Project, limits: Array<Coordinate>) {
        project.map.limits = limits;
        return this.updateProject(project);
    }

    async updateName(project: Project, name: string) {
        project.name = name;
        return this.updateProject(project);
    }

    async updateLayerCount(project: Project, count: number) {
        project.map.layer_count=count
        await this.updateProject(project)
    }

    async update_backend_object(prepare_dem_url: string, value: any) {
        const requestOptions = {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(value)
        };
        (await fetch(prepare_dem_url.replace("http://","https://"), requestOptions)).json();
    }

    async launch_prepare_dem(project: Project) {
        await fetch(MapMakerApi.URLS.launch_sub_tasks("prepare_dem",project))
    }

    async launch_prepare_contours(project: Project) {
        await fetch(MapMakerApi.URLS.launch_sub_tasks("prepare_contours",project))

    }

    async generate_svg(project: Project) {
        await fetch(MapMakerApi.URLS.launch_sub_tasks("generate_svg",project))
    }
    async run_all(project:Project){
        await fetch(MapMakerApi.URLS.launch_sub_tasks("all",project))
    }

    async getBackend(prepare_contours_urls: string) {
        if(prepare_contours_urls.includes("worldofwood.co")){
            prepare_contours_urls=prepare_contours_urls.replace("http://","https://")
        }
        return (await fetch(prepare_contours_urls)).json()
    }
}
