import Project from "../../model/Project";
import "./ConfigPanel.css";
import React, {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faEdit, faRedoAlt, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import {ConfigEvent, ConfigEventCallback, ConfigEventType, SCREEN_SIZE_QUERY} from "../../App";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {PlacementStepConfig} from "./PlacementStepConfig";
import {AdminPane} from "./AdminPane";
import {OverlayStepConfig} from "./OverlayStepConfig";
import {debounce} from "lodash";
import {LevelsStepConfig} from "./LevelsStepConfig";


export interface StepConfigProps {
    project: Project,
    on_config_event: ConfigEventCallback,
}





function PlacedStepConfig(props: { change_placement: () => void }) {
    return <div className={"flex-row config-section"} style={{justifyContent: "space-between"}}>
        <h3>Limits</h3>
        <div className={"flex-row "}>
            <small className={"flex-row "} style={{alignItems: "center"}}>
                <div className={"flex-row valid vignette"}>
                    Picked&nbsp; <FontAwesomeIcon icon={faCheckCircle}/>
                </div>
            </small>
            <button style={{paddingLeft: "0.5em", paddingRight: "0.5em"}} onClick={props.change_placement}
                    className={"line"}>Change
            </button>
        </div>
    </div>;
}

function FinalPreviewConfig(props: { on_config_event: ConfigEventCallback, project: Project }) {
    let [copied, setCopied] = useState(false)
    const save = () => {
        navigator.clipboard.writeText(`${window.location.hostname}/map/${props.project.id}`)
        setCopied(true)
        setTimeout(() => setCopied(false), 1500)
    }
    const edit=()=>{
        props.on_config_event({eventType:ConfigEventType.EDIT_MAP})
    }
    let content;
    if (copied) {
        content =
            <CSSTransition in={true} timeout={5000} classNames={"fade-transition"}>
                <div className={"text-center fade-transition"}>
                    <h2>We copied the map's link to your clipboard</h2>
                    <small>Paste it where you can retrieve it</small>
                </div>
            </CSSTransition>
    } else {
        content = <div className={"text-center"}>
            <div className={"flex-row center small-line"}>
                <button  onClick={() => props.on_config_event({eventType: ConfigEventType.RESET_PROJECT})}>New
                    map <FontAwesomeIcon icon={faRedoAlt}/></button>
                <button onClick={edit} className={"btn-highlight"}>Edit <FontAwesomeIcon icon={faEdit}/></button>
                <button className={"green"}  onClick={() => save()}>Save <FontAwesomeIcon icon={faSave}/></button>
            </div>
            <div className={"flex-column"}>
                <h2>Here is what your map will look like</h2>
                <button className={"btn-big green"}>Order it on Kickstarter</button>
            </div>


        </div>
    }
    return <div>

        {content}


    </div>;
}

function NameConfig(props: { on_config_event: (event: ConfigEvent) => Promise<void>, project: Project }) {
    let [name,setName]=useState(props.project.name);
    let onNameChange = useMemo(() => debounce((name:string) => {
        props.on_config_event({eventType:ConfigEventType.NAME_CHANGE,payload:name})
    }, 500, {leading: false}), []);
    let onChange = (event:any) => {
        setName(event.target.value);
        onNameChange(event.target.value);
    }
    return <div>
        <label htmlFor="name">Name</label>
        <input type="text" name={"name"} value={name} onChange={onChange}/>
    </div>;
}


export function ConfigPanel(props: { project: Project, on_config_event: ConfigEventCallback }) {
    const project = props.project;
    let steps = [];
    // let current_step_type = project.current_step.type;
    let class_names = ""
    const [isValidated,setIsValidated] = useState(false)
    const [validatedDisabled,setValidatedDisabled] = useState(false)
    let onConfigEvent = async (event:ConfigEvent)=>{
        setValidatedDisabled(true)
        await props.on_config_event(event)
        setValidatedDisabled(false);
    };
    steps.push(<PlacementStepConfig key={"Placing"}  project={project}
                                    on_config_event={onConfigEvent}
    />)
    steps.push(<LevelsStepConfig key={"Levels"} project={props.project} on_config_event={props.on_config_event}/>)
    steps.push(<OverlayStepConfig key={"Overlays"} project={project} on_config_event={onConfigEvent}/>)
    steps.push(<NameConfig key={"Name"} project={project} on_config_event={onConfigEvent}/>)
    let validate = async () => {
        setIsValidated(true)
        await onConfigEvent({eventType:ConfigEventType.VALIDATE_PLACEMENT});
        setIsValidated(false);
    }

    let placed = false;
    return <div className={"config-panel-container " + class_names}>
        <div className={"config-panel " + class_names}>
            {isValidated && <CSSTransition in={true} timeout={10000} classNames={"config-panel"}>
                <div className={"loading-overlay"}>
                    <h1 className={"text-center"}>Validating</h1>
                    <progress className={"pure-material-progress-circular"}/>
                </div>
            </CSSTransition>}

            <TransitionGroup>
                {steps.map(
                    c => <CSSTransition key={c.key} in={true} classNames={"config-panel"} timeout={1000}>
                        {c}
                    </CSSTransition>)
                }</TransitionGroup>

            <div style={{display: "flex", placeContent: "space-between"}}>
                <button style={{padding: "11px"}} className={"btn-orange"}
                        onClick={() => onConfigEvent({eventType: ConfigEventType.RESET_PROJECT})}>
                    <FontAwesomeIcon

                        icon={faTrash}/></button>
                <AdminPane>
                    <a href={"/gallery"}> Gallery</a>
                </AdminPane>
                <button style={{padding: "11px"}} className={"green"} disabled={!placed || isValidated || validatedDisabled} onClick={validate}
                        >Validate
                </button>
            </div>
        </div>
    </div>;
}
