import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import Project from "./model/Project";
import {ProjectService} from "./model/ProjectService";
import firebase from "firebase";
// @ts-ignore
import {FirestoreProvider} from 'react-firestore';
import {ConfigPanel} from "./components/config/ConfigPanel";
import {MapPreview} from "./components/config/MapPreview";
import {ErrorBoundary} from "@sentry/react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedoAlt, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {MapTypes} from "./model/MapTypes";
import AdminLoginWall from "./components/AdminLoginWall";
import Overlay from "./model/Overlay";
import {ProjectGallery} from "./ProjectGallery";
import {LatLngBounds} from "leaflet";
import {AdminPane} from "./components/config/AdminPane";
import AdminConfigPanel from "./components/config/admin/AdminConfigPanel";

var firebaseConfig = {
    apiKey: "AIzaSyBCRTQE8dUQvfE1YqEQWTRKxSmLa4aBa6Q",
    authDomain: "worldofwood.firebaseapp.com",
    projectId: "worldofwood",
    storageBucket: "worldofwood.appspot.com",
    messagingSenderId: "650555294747",
    appId: "1:650555294747:web:62c99b5e06cb56212f64be",
    measurementId: "G-XLL4283G0P"
};
firebase.initializeApp(firebaseConfig);
export const ProjectContext = React.createContext(new ProjectService(true));

export enum ConfigEventType {
    CLEAR_MAP = "ClearMap",
    CENTER_MAP = "CenterMap",
    PLACE_MAP = "PlaceMap",
    RESET_PROJECT = "Reset",
    VALIDATE_PLACEMENT = "ValidatePlacement",
    PICK_LEVELS = "PickLevels",
    VALIDATE_OVERLAYS_LEVELS = "ValidateLevels&Overlays",
    SELECT_OVERLAYS = "SelectOverlays",
    EDIT_MAP = "EditMap",
    MAP_TYPE = "MapType",
    SELECT_BORDER = "SelectBorders",
    NAME_CHANGE="NameChange",
    TOGGLE_HIDDEN="ToggleHidden",
    MAP_RATIO="MapRatio",
}

export interface ConfigEvent {
    eventType: ConfigEventType,
    payload?: any
}

export interface MapLayers {
    contours?: number,
    overlays: Array<Overlay>
}


export type ConfigEventCallback = (event: ConfigEvent) => Promise<void>


function ProjectView() {
    const projectService = useContext(ProjectContext)
    let [project, setProject] = useState<Project | undefined>(undefined)
    let [placeHere, setPlaceHere] = useState<boolean>(false);
    let [clearMap, setClearMap] = useState<boolean>(false);
    let [layers, setLayers] = useState<MapLayers>({contours: undefined, overlays: []})
    let [mapCenter, setMapCenter] = useState<null | google.maps.places.PlaceResult>(null)
    let [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
    let [hiddenLayers,setHiddenLayers] = useState<Set<string>>(new Set<string>());
    let [mapRatio,setMapRatio] = useState<null|{height:number,width:number}>(null);
    if (project!==undefined && project.name!==undefined){
        document.title=`${project.name} - Map - ${project.id}`
    }
    useEffect(() => {
        projectService.loadOrCreateProject().then(
            (project) => {
                setProject(project)
            }
        )
    }, [])

    if (project === undefined) {
        return <div className={"text-center loading-screen"} style={{display: "flex"}}>
            <h1>Loading Map Project</h1>
            <progress className={"pure-material-progress-circular"}/>
        </div>
    } else {
        const update_project = (update: (prevState?: Project) => Project | undefined) => {
            setProject(update)

        }

        const resetProject = async () => {
            let newProject = await projectService.resetProject()
            setProject(newProject);
        }
        const update_map_type = async (type: MapTypes) => {
            let newProject = await projectService.resetProject(type)
            setProject(newProject)
        }
        const placeMap = () => {
            setPlaceHere(true);
        }
        const placedMap = (latLngBounds: LatLngBounds) => {
            const ne=latLngBounds.getNorthEast();
            const sw=latLngBounds.getSouthWest();

            if(project)projectService.updateLimits(project,
                [
                    {latitude:parseFloat(ne.lat.toFixed(10)),longitude:parseFloat(ne.lng.toFixed(10))},
                    {latitude:parseFloat(sw.lat.toFixed(10)),longitude:parseFloat(sw.lng.toFixed(10))}
                ]
            ).then(setProject)
            setPlaceHere(false);
        }
        const clear_map =  () => {
            if(project){
                projectService.updateLimits(project,[]).then(setProject);
            }
            setClearMap(!clearMap);
        }
        const validate_placement = async () => {
            setMapCenter(null);
        }
        const center_map = (place: google.maps.places.PlaceResult) => {
            setMapCenter(place)
        }

        const validate_overlays_levels = async () => {
            //TODO: Update with new overlay system

            // const result = await project?.step(StepTypes.OVERLAY)?.validate(projectService);
            // await setProject(prevState => {
            //     const updated = projectService.updateCurrentStep(projectService.updateStep(prevState!, result!), result!)
            //     projectService.updateCurrentProject(updated);
            //     return updated
            // })
        }
        const pick_levels = async (payload: { count: number }) => {
            setLayers(prevState => {
                return {...prevState, contours: payload.count,}
            })
            if (project)
                projectService.updateLayerCount(project,payload.count)
            //TODO: Update with new step system

            // await project?.step(StepTypes.LEVELS_PICKING)?.validate(projectService);
        }
        const select_overlays = async (payload: Array<Overlay>) => {
            setLayers(
                prevState => {
                    return {...prevState, overlays: payload}
                }
            )
            if (project){
                const new_project = await projectService.updateOverlays(project,payload)
                setProject(prevState => {
                        if(prevState===undefined)return prevState;
                        return new_project
                                            }
                )
            }

        }
        const select_borders = async (id: number) => {
            //TODO: Update with new Step system

            // let step = project?.step(StepTypes.BORDER_PICKING) as BorderPicking;
            // step.selected_id = id
            // await step?.validate(projectService)

        }
        const edit_map = async () => {
            //TODO: update with new Step system
            // const levels_picking = project?.step(StepTypes.LEVELS_PICKING);

            // await setProject(prevState => {
            //     const updated = projectService.updateCurrentStep(prevState!, levels_picking!)
            //     projectService.updateCurrentProject(updated);
            //     return updated
            // })
        }
        const name_change = async (name:string)=>{
            if(project){
                let new_project = await projectService.updateName(project,name);
                setProject(prevState => new_project);
            }
        }
        const toggle_hidden_layer= (id:string)=>{
            console.log(hiddenLayers)
            if (hiddenLayers.has(id)){
                hiddenLayers.delete(id)
                setHiddenLayers(new Set(hiddenLayers))
            }else{

                setHiddenLayers(new Set(hiddenLayers).add(id));
            }

        }
        const map_ratio_update=(ratio:any)=>{
            setMapRatio(ratio);

        }
        const on_config_event: ConfigEventCallback = async (event: ConfigEvent) => {
            let callback = (payload: any) => {
            };
            switch (event.eventType) {

                case ConfigEventType.CENTER_MAP:
                    callback = center_map;
                    break;
                case ConfigEventType.CLEAR_MAP:
                    callback = clear_map;
                    break;
                case ConfigEventType.PLACE_MAP:
                    callback = placeMap;
                    break;
                case ConfigEventType.SELECT_OVERLAYS:
                    callback = select_overlays;
                    break;
                case ConfigEventType.RESET_PROJECT:
                    callback = resetProject;
                    break;
                case ConfigEventType.VALIDATE_PLACEMENT:
                    callback = validate_placement;
                    break;
                case ConfigEventType.VALIDATE_OVERLAYS_LEVELS:
                    callback = validate_overlays_levels;
                    break;
                case ConfigEventType.PICK_LEVELS:
                    callback = pick_levels;
                    break;
                case ConfigEventType.EDIT_MAP:
                    callback = edit_map;
                    break;
                case ConfigEventType.MAP_TYPE:
                    callback = update_map_type;
                    break;
                case ConfigEventType.SELECT_BORDER:
                    callback = select_borders;
                    break;
                case ConfigEventType.NAME_CHANGE:
                    callback = name_change;
                    break;
                case ConfigEventType.TOGGLE_HIDDEN:
                    callback = toggle_hidden_layer;
                    break;
                case ConfigEventType.MAP_RATIO:
                    callback=map_ratio_update;
                    break;
            }
            await callback(event.payload);
        }
        console.log("Project: ",project)
        return <div className={"mapmaker_root"}>
            <MapPreview project={project} update_project={update_project} placed_map={placedMap} place_here={placeHere}
                        cleared_map={clear_map} clear_map={clearMap}  map_center={mapCenter} hidden_layers={hiddenLayers}
                        map_ratio={mapRatio}
            />
            <ConfigPanel project={project} on_config_event={on_config_event}/>
            <AdminPane>
                <AdminConfigPanel project={project} on_config_event={on_config_event}/>
            </AdminPane>
        </div>
    }
}


function ErrorScreen() {
    const projectService = new ProjectService(false)
    return (
        <div className={"text-center loading-screen error-screen"}>
            <FontAwesomeIcon icon={faWindowClose} size={"9x"}/>
            <h1>An error occured while working on your map</h1>
            <div><strong>We are investigating</strong></div>
            <div style={{margin: "5px"}}>
                <strong>In the meantime message us
                    <a rel={"noreferrer"} className={"btn btn-facebook"} target={"_blank"}
                       href={"https://www.facebook.com/WorldOfWoodFr/"}>
                        on facebook
                    </a>
                    or
                    <a rel={"noreferrer"} className={"btn btn-instagram"} target={"_blank"}
                       href={"https://instagram.com/worldofwoodfr/"}>
                        on instagram
                    </a>
                </strong>
            </div>
            <div><strong> or email us at</strong></div>
            <a className={"btn btn-highlight"} href="mailto:contact@worldofwood.co">Contact@worldofwood.co</a>
            <div>
                <button onClick={() => projectService.retryInError()}> Retry <FontAwesomeIcon icon={faRedoAlt}/>
                </button>
            </div>
        </div>
    );
}

function MaintenanceScreen() {
    return (
        <div className={"text-center loading-screen "} style={{color: "#986a38"}}>
            <img src="/logo_marron-279x300.png" alt=""/>
            <h1>Maitenance In Progress</h1>
            <div style={{marginBottom: "5px"}}>
                <small>We are comming back in a few hours</small>
            </div>
            <div style={{margin: "5px"}}>
                <strong>In the meantime message us
                    <a rel={"noreferrer"} className={"btn btn-facebook"} target={"_blank"}
                       href={"https://www.facebook.com/WorldOfWoodFr/"}>
                        on facebook
                    </a>
                    or
                    <a rel={"noreferrer"} className={"btn btn-instagram"} target={"_blank"}
                       href={"https://instagram.com/worldofwoodfr/"}>
                        on instagram
                    </a>
                </strong>
            </div>

        </div>
    );
}


function App() {
    let admin = true// Supply from a env uppon publicily
    let projectService = new ProjectService(admin);
    let maintenance = false;
    let gallery = window.location.pathname.includes("gallery")
    if (maintenance)
        return <MaintenanceScreen/>
    return (
        <ErrorBoundary fallback={ErrorScreen}>
            <FirestoreProvider firebase={firebase}>
                <AdminLoginWall>
                    <ProjectContext.Provider value={projectService}>
                        {gallery?<ProjectGallery/>:<ProjectView/>}
                    </ProjectContext.Provider>
                </AdminLoginWall>
            </FirestoreProvider>
        </ErrorBoundary>

    );
}

export default App;
export const SCREEN_SIZE_QUERY = '(min-width: 768px)';
