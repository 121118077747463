import Project from "../../../model/Project";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {ProjectContext} from "../../../App";
import {debounce} from "lodash";
import {Collapsable} from "../Collapsable";

export function PrepareDemConfig(props: { project: Project }) {
    let projectService = useContext(ProjectContext);
    let [prepareDem, setPrepareDem] = useState(
        {
            resolution_factor: 0.005,
            resolution_overide: 0,
            sigma: 10.0,
            radius: 4,
            use_coast: true,
            skip_resample: false,
            skip_smoothing: false,
        }
    )
    useEffect(() => {
        projectService.getBackend(props.project.prepare_dem_url).then(
            (dem)=>{
                setPrepareDem(dem);
            }
        )
    },[props.project.id])
    let updateBackend = useMemo(() => debounce((value: any) => {

        projectService.update_backend_object(props.project.prepare_dem_url, value);
    }, 500, {leading: false}), [])
    let updatePrepareDem = (newValues: { [key: string]: any }) => {

        const newPrepareDem = {...prepareDem, ...newValues};
        setPrepareDem(newPrepareDem);
        updateBackend(newPrepareDem);
    }

    return <div>
        <Collapsable title={        <h4>Prepare Dem</h4>
        }>
            <label htmlFor="resolution_factor">Resolution Factor</label>
            <input type="number" name={"resolution_factor"} value={prepareDem.resolution_factor}
                   onChange={(event) => updatePrepareDem({resolution_factor: event.target.value})}/>
            <label htmlFor="resolution_overide">Resolution_overide</label>
            <input type="number" name={"resolution_overide"} value={prepareDem.resolution_overide!==null?prepareDem.resolution_overide:0}
                   onChange={(event) => updatePrepareDem({resolution_overide: event.target.value!=="0"?event.target.value:null})}/>
            <label htmlFor="sigma">sigma</label>
            <input type="number" name={"sigma"} value={prepareDem.sigma}
                   onChange={(e) => updatePrepareDem({sigma: e.target.value})}/>
            <label htmlFor="radius">radius</label>
            <input type="number" name={"radius"} value={prepareDem.radius}
                   onChange={(e) => updatePrepareDem({radius: e.target.value})}/>
            <label htmlFor="skip_resample">skip resample</label>
            <input type="checkbox" name={"skip_resample"} checked={prepareDem.skip_resample}
                   onChange={(e) => updatePrepareDem({skip_resample: !prepareDem.skip_resample})}/>
            <label htmlFor="skip_smoothing">skip smoothing</label>
            <input type="checkbox" name={"skip_smoothing"} checked={prepareDem.skip_smoothing}
                   onChange={(e) => updatePrepareDem({skip_smoothing: !prepareDem.skip_smoothing})}/>

        </Collapsable>
        <button onClick={() => projectService.launch_prepare_dem(props.project)}>Launch</button>
    </div>;
}
