import React, {useContext, useMemo, useState} from 'react';
import Project from "../../../model/Project";
import {ConfigEventCallback, ConfigEventType, ProjectContext} from "../../../App";
import {PrepareDemConfig} from "./PrepareDemConfig";
import {PrepareContourConfig} from "./PrepareContourConfig";
import "./AdminConfigPanel.css"
import {Collapsable} from "../Collapsable";
import {debounce} from "lodash";
import {Jobs} from "./Jobs";

function LayerVisibilityConfig(props: { on_config_event: ConfigEventCallback, project: Project }) {
    let [hiddenLayers, setHiddenLayers] = useState(new Set<string>());

    let layers = props.project.map.overlays.map(v => v.name);
    layers.push("Contours")
    return <div>

        <Collapsable title={<h4>Hide Layer</h4>}>
            <div>
                {layers.map(l => <button className={hiddenLayers.has(l) ? "btn-highlight" : ""} onClick={() => {
                        if (hiddenLayers.has(l)) {
                            setHiddenLayers(hiddenLayers => {
                                hiddenLayers.delete(l);
                                return hiddenLayers;
                            });
                        } else {
                            setHiddenLayers(hiddenLayers => {
                                hiddenLayers.add(l);
                                return hiddenLayers;
                            });
                        }
                        props.on_config_event({eventType: ConfigEventType.TOGGLE_HIDDEN, payload: l})
                    }}>{l}</button>
                )}
            </div>
        </Collapsable>
    </div>
}

function LayerCountConfig(props: { on_config_event: ConfigEventCallback, project: Project }) {
    let [layer_count, setLayerCount] = useState(props.project.map.layer_count)
    let [updating, setUpdating] = useState(false);
    console.log("updating ", updating)
    let updateBackend = useMemo(() => debounce(async (value: any) => {
        await setUpdating(true)
        console.log("query")
        await props.on_config_event({eventType: ConfigEventType.PICK_LEVELS, payload: {count: value}});
        console.log("query done")
        await setUpdating(false)
    }, 500, {leading: false}), [setLayerCount])
    let updateCount = (value: string) => {
        setLayerCount(parseInt(value))
        updateBackend(value);
    }
    return <div>

        <label htmlFor="contour_base">Layer Count </label>
        <input type="number" name={"contour_base"} disabled={updating}
               value={layer_count}
               onChange={(event) => updateCount(event.target.value)}/>

    </div>;
}

function MapRatioConfig(props: { on_config_event: ConfigEventCallback }) {
    let [height, setHeight] = useState(29);
    let [width, setWidth] = useState(21);
    let [locked, setLocked] = useState(false);
    let triggerEvent = useMemo(() => debounce((locked,height,width) => {
        if (locked)
        {
            props.on_config_event({eventType: ConfigEventType.MAP_RATIO, payload: {"width": width, "height": height}})
        }
        else{
            props.on_config_event({eventType: ConfigEventType.MAP_RATIO, payload: null})

        }
    }, 500, {leading: false}), [])
    let update_height = (value: number) => {
        setHeight(value);
        triggerEvent(locked,value,width);
    }
    let update_width = (value: number) => {
        setWidth(value);
        triggerEvent(locked,height,value);
    }
    let update_lock = (value: boolean) => {
        setLocked(value);
        triggerEvent(value,height,width)
    }
    return <div>
        <div>Height x Width</div>
        <div>
            <input name={"height"}
                   value={height}
                   onChange={(event) => update_height(parseFloat(event.target.value))}/>
            <input name={"width"}
                   value={width}
                   onChange={(event) => update_width(parseFloat(event.target.value))}/>
        </div>
        <div>
            <label htmlFor="lock_ratio">lock</label>
            <input type="checkbox" name={"lock_ratio"} checked={locked}
                   onChange={(e) => update_lock(e.target.checked)}/>

        </div>
    </div>

}

function AdminConfigPanel(props: { project: Project, on_config_event: ConfigEventCallback }) {
    let projectService = useContext(ProjectContext);
    let generate_svg = () => {
        projectService.generate_svg(props.project)
    }
    return (
        <div className={"admin-config-panel-container"}>
            <div className={"admin-config-panel"}>
                <h4>Admin pane</h4>
                <LayerCountConfig project={props.project} on_config_event={props.on_config_event}/>
                <LayerVisibilityConfig project={props.project} on_config_event={props.on_config_event}/>
                <PrepareDemConfig project={props.project}/>
                <PrepareContourConfig project={props.project}/>
                <MapRatioConfig on_config_event={props.on_config_event}/>
                <Jobs project={props.project}/>
                <button onClick={generate_svg}>Generate Svg</button>
                <button onClick={() => {
                    projectService.run_all(props.project)
                }}>Generate All
                </button>

            </div>
        </div>
    );
}

export default AdminConfigPanel;
