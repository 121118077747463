import React, {Fragment} from "react";
import {TILE_SERVER_URLS, VECTOR_LAYER_STYLES, VectorLayer} from "./VectorLayer";
import Overlay from "../../model/Overlay";

export function OverlayLayer(props: { overlay:Overlay}) {
    return (
        <Fragment>
            <VectorLayer
                vectorPath={TILE_SERVER_URLS.VECTOR_PATH(props.overlay.database,props.overlay.query)}
                layerStyle={props.overlay.get_styles()}
            />
        </Fragment>
    )
}
