import Project from "../../../model/Project";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {ProjectContext} from "../../../App";
import {debounce} from "lodash";
import {Collapsable} from "../Collapsable";

export function PrepareContourConfig(props: { project: Project }) {
    let projectService = useContext(ProjectContext);
    let [prepareContours, setPrepareContours] = useState<{
        contour_base: null | number,
        max_elevation: null | number,
        area_filter_ratio: null | number,
        length_filter_ratio: null | number,
        cut_base: boolean,
    }>(
        {
            contour_base: null,
            max_elevation: null,
            area_filter_ratio:null,
            length_filter_ratio:null,
            cut_base:false,
        }
    )
    useEffect(() => {
         projectService.getBackend(props.project.prepare_contours_urls).then(
             (contours)=>{

                 setPrepareContours(contours);
             }
         )
    },[props.project.id])
    let updateBackend = useMemo(() => debounce((value: any) => {
        projectService.update_backend_object(props.project.prepare_contours_urls, value);
    }, 500, {leading: false}), [])
    let updatePrepareDem = (newValues: { [key: string]: any }) => {
        const newPrepareDem = {...prepareContours, ...newValues};
        setPrepareContours(newPrepareDem);
        updateBackend(newPrepareDem);
    }

    return <div>
        <Collapsable title={        <h4>Prepare Contours</h4>
        }>
            <div><label htmlFor="contour_base">Contour Base</label>
                <input type="number" name={"contour_base"}
                       value={prepareContours.contour_base === null ? 0 : prepareContours.contour_base}
                       onChange={(event) => updatePrepareDem({contour_base: parseFloat(event.target.value)})}/>
            </div>
            <div>
                <label htmlFor="set_contour_base">setContourBase</label>
                <input type="checkbox" name={"set_contour_base"} checked={prepareContours.contour_base !== null}
                       onChange={(e) => updatePrepareDem({contour_base: null})}/>
            </div>
            <div>
                <label htmlFor="max_elevation">Max Elevation</label>
                <input type="number" name={"max_elevation"}
                       value={prepareContours.max_elevation === null ? 0 : prepareContours.max_elevation}
                       onChange={(event) => updatePrepareDem({max_elevation: parseFloat(event.target.value)})}/>
            </div>
            <div>
                <label htmlFor="set_contour_base">set max elevation</label>
                <input type="checkbox" name={"set_contour_base"} checked={prepareContours.max_elevation !== null}
                       onChange={(e) => updatePrepareDem({max_elevation: null})}/>
            </div>
            <div><
                label htmlFor="area_filter_ratio">Area Filter </label>
                <input type="number" name={"area_filter_ratio"}
                       value={prepareContours.area_filter_ratio === null ? 0 : prepareContours.area_filter_ratio}
                       onChange={(event) => updatePrepareDem({area_filter_ratio: parseFloat(event.target.value)})}/>
            </div>
            <div>
                <label htmlFor="set_area_filter">set area filter</label>
                <input type="checkbox" name={"set_area_filter"} checked={prepareContours.area_filter_ratio !== null}
                       onChange={(e) => updatePrepareDem({area_filter_ratio: null})}/>
            </div>
            <div>
                <label htmlFor="set_area_filter">Cut Base</label>
                <input type="checkbox" name={"cut_base"} checked={prepareContours.cut_base}
                        onChange={(e) => updatePrepareDem({cut_base:!prepareContours.cut_base})}/>
            </div>
        </Collapsable>
        <button onClick={() => projectService.launch_prepare_contours(props.project)}>Launch</button>
    </div>;
}
