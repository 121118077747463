import React, {Fragment, ReactNode, useState} from "react";

export function Collapsable(props: { title: ReactNode, children: ReactNode }) {
    let [collapse, setCollapsed] = useState(false);
    return <Fragment>
        <div className={"flex-row"}>
            {props.title}
            <button onClick={() => setCollapsed(!collapse)}>{collapse ? "Show" : "Hide"}</button>
        </div>
        {!collapse && props.children}
    </Fragment>
}
