import L from "leaflet";
import React, {useContext, useEffect, useState} from "react";
import {LeafletMapContext} from "./LeafletMapProvider";

export interface LeafletSubComponentProps {
    add: (map: L.Map) => any;
    remove: (map: L.Map, context: any) => void;
    children?:JSX.Element
}
let SubComponentContext = React.createContext(undefined)
export function SubComponentEffect(props:{effect:any,args?:any}){
    let context = useContext(SubComponentContext);
    useEffect(()=>{
        props.effect(context,props.args)
    },[props.effect,context,props.args])
    return null;
}
export function LeafletSubComponent(props: LeafletSubComponentProps) {
    let map = useContext(LeafletMapContext)
    let {add,remove} = props;
    useEffect(() => {
        let context: undefined = undefined
        if (map) {
            context = add(map)
        }
        return () => {
            if (map) {
                remove(map!, context)
            }
        }
    }, [add,remove,map])
    return null;
}
