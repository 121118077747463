import React, {ComponentType, ReactElement, useEffect, useState} from "react";

// @ts-ignore
import {FirestoreCollection as Collection} from 'react-firestore';
import firebase from "firebase";


export function FirestoreCollection(props:any) {
    return (
        <Collection {...props}/>
    );
}

export interface FirestoreDocumentsState{
    documents?:Array<firebase.firestore.DocumentData>
    loading:boolean
}
export function FirestoreDocuments(props:{ids:Array<string>,collection:string,render:(f:FirestoreDocumentsState)=>ReactElement}){
    const {ids,collection} = props;
    const [state,setState] = useState<FirestoreDocumentsState>({documents:undefined,loading:true})
    useEffect(()=>{

        const db = firebase.firestore()
        db.collection(collection).where("id","in",ids).onSnapshot((snapshot)=>{
                setState({documents:snapshot.docs.map(snap=>snap.data()).filter(d=>d!==undefined) as Array<firebase.firestore.DocumentData>,loading:false})
        })
    },[ids,collection])

    return (
        <div>{props.render(state)}</div>
        )
}

export interface FirebaseStorageFilesState{
    urls?:Array<string>,
    isLoading:boolean
}
export function FirebaseStorageFiles(props:{files:Array<string>,render:(f:FirebaseStorageFilesState)=>ReactElement}){
    const {files} = props;

    const [state,setState] = useState<FirebaseStorageFilesState>({urls:undefined,isLoading:true})
    useEffect(()=>{

        const storage = firebase.storage();
        Promise.all(files.map((s)=>storage.ref(s)).map((d)=>d.getDownloadURL())).then(

            (data)=>{
                setState({urls:data as string[],isLoading:false})
            }
        )
    },[files])

    return (
        <div>{props.render(state)}</div>
    )
}
type FileRenderProps = {isLoading: boolean,url?:string};
export function FirebaseStorageFile(props:{file:string,render_component:ComponentType<FileRenderProps>}){
    const {file} = props;
    const [state,setState] = useState<{isLoading:boolean,url?:string}>({url:undefined,isLoading:true})
    useEffect(()=>{
        firebase.storage().ref(file).getDownloadURL().then(
            (data)=>{
                setState({url:data,isLoading:false})
            }
        )
    },[file])
    return <props.render_component {...state}/>
}
type DocumentRenderProps = { isLoading: boolean, document:any};
type FirebaseDocumentState={isLoading:boolean,document:any}


export function FirestoreDocument(props:{collection:string,path:string,render_component:ComponentType<DocumentRenderProps>}){
    const {path} = props;
    const [state,setState] = useState<FirebaseDocumentState>({document:undefined,isLoading:true})
    useEffect(()=>{
        const db = firebase.firestore();
         db.collection(props.collection).doc(path).onSnapshot((doc) => {
             console.log(doc)
             setState({document: doc.data(), isLoading: false});
         })

    },[path])

    return <props.render_component {...state}/>
}
export function FirestoreCollectionView(props:{collection:string,render_component:ComponentType<DocumentRenderProps>}){

    const [state,setState] = useState<FirebaseDocumentState>({document:undefined,isLoading:true})
    useEffect(()=>{
        const db = firebase.firestore();
        db.collection(props.collection).onSnapshot((doc) => {
            setState({document: doc, isLoading: false});
        })
    },[props.collection])

    return <props.render_component {...state}/>
}
