import Project from "../../model/Project";
import {ConfigEvent, ConfigEventCallback, ConfigEventType, SCREEN_SIZE_QUERY} from "../../App";
import React, {useState} from "react";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
function LevelsPicker(props: { on_config_event: (event: ConfigEvent) => void }) {
    const [selected_number_of_levels, setPicked] = useState<number>(3);
    const isDesktop = useMediaQuery(SCREEN_SIZE_QUERY);
    const galery_horientation = isDesktop ? "vertical-galery-3-col" : "horizontal-galery"
    //FIXME:use dynamic values
    const levels = [3,5,7];
    const pickValue = (value: number) => {

        props.on_config_event({
            eventType: ConfigEventType.PICK_LEVELS,
            payload:{count:value}
        })
        setPicked(value);
    }

    return <div className={"center flex-row " + galery_horientation}>
        {levels.map((value) => {
            let className = "";
            if (value === selected_number_of_levels) {
                className = "btn-highlight";
            }
            return <button key={value} className={"level-miniature " + className} onClick={() => {
                pickValue(value)
            }}> {value}</button>
        })}
    </div>

}

export function LevelsStepConfig(props: { project: Project, on_config_event: ConfigEventCallback }) {

    return <div className={"step-view"}>
        <h3>Number of layers</h3>
        <LevelsPicker on_config_event={props.on_config_event}/>
    </div>;
    return null;
}
